import React, { ReactElement } from 'react'
import _ from 'lodash'

import styled from 'styled-components'
import { Col, Row } from 'antd'

import { palette, mediaQueries } from '~components/homepage/styled/constants'

import { SVGImage } from '~components/homepage/svg/SVGImage'
import { KlosySVGId } from '../svg/types/svg'

export const MaterialGridContainer = styled(Row)`
  max-width: 900px;
  margin: 0 auto;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @media (max-width: ${mediaQueries.small}) {
    & {
      padding: 15px;
    }
  }

  .desktop-description {
    @media (max-width: ${mediaQueries.small}) {
      & {
        display: none;
      }
    }
  }
`

export const MaterialRow = styled(
  ({ disableLine, ...rest }): ReactElement => <Row {...rest} />
)`
  border-bottom: ${({ disableLine }): string =>
    !disableLine ? '1px solid white' : 'none'};
  text-align: center;
  overflow: hidden;

  --color-hover: transparent;

  @media (max-width: ${mediaQueries.small}) {
    & {
      border-bottom: none;
    }
  }
`

export const MaterialCol = styled(Col)`
  margin: 40px auto;
  cursor: pointer;

  &.active {
    --color-hover: white;
  }

  svg {
    transition-duration: 5s;
  }

  &:hover {
    --color-hover: white;

    .color-transition {
      fill: white;
    }

    svg {
      transform: scale(1.1);
    }
  }

  .material-image {
    width: 85px;
    height: 85px;
    margin: 0 auto;

    .gatsby-image-wrapper {
      height: 100%;
      border-radius: 50%;
    }
  }

  .knife-name,
  .price {
    font-size: 11px;
    color: ${palette.darkBlue};
    text-align: center;

    &.material-price {
      position: absolute;
      width: 100%;
      background: ${palette.beige};
      z-index: 10;

      @media (max-width: ${mediaQueries.small}) {
        & {
          position: relative;
          display: inline-block;
          height: 11px;
        }
      }
    }
  }

  .stick {
    width: 1px;
    height: 100%;
    background: ${palette.darkBlue};
    position: absolute;
    left: 49%;
    top: 100%;
    transform: translateY(12px);
    opacity: 0;
    transition: 0.5s;

    &.active-stick {
      opacity: 1;
    }

    @media (max-width: ${mediaQueries.small}) {
      & {
        display: none;
      }
    }
  }

  .knife-name {
    margin-top: 15px;
    font-family: 'Averta-Bold', sans-serif;
  }

  .price {
    margin-top: 7px;
  }

  .info-sign {
    text-align: center;
    padding-top: 8px;
    display: none;

    @media (max-width: ${mediaQueries.small}) {
      & {
        display: block;
      }
    }
  }
`

export const KnifeSizeImage = styled.div<{ active?: boolean }>`
  text-align: center;
  height: 43px;

  @media (max-width: ${mediaQueries.small}) {
    & {
      width: 95% !important;
      height: 36px;
    }
  }

  svg {
    width: 100%;

    .color-transition {
      fill: ${({ active }) => (active ? 'white' : 'transparent')};
    }

    path {
      transition: 0.3s;
    }

    &:hover {
      .color-transition {
        fill: white;
      }
    }
  }

  &.single-knife-image {
    svg {
      @media (max-width: ${mediaQueries.small}) {
        & {
          width: 80% !important;
        }
      }
    }
  }

  &.Peeling {
    svg {
      width: 100px;
    }
  }

  &.Punching {
    svg {
      width: 142px;
    }
  }

  &.Filleting {
    svg {
      width: 178px;
    }
  }

  &.Boning {
    svg {
      width: 151px;
    }
  }

  &.Serrated {
    svg {
      width: 203px;
    }
  }

  &.LightAsianCleaver {
    svg {
      height: 38px;
    }
  }

  &.Cleaver {
    svg {
      height: 36px;
      width: 143px;
    }
  }

  &.Yanagiba {
    svg {
      width: 198px;
    }
  }

  &.Chef240 {
    svg {
      height: 36px;
      width: 193px;
    }
  }

  &.Chef210 {
    svg {
      height: 30px;
      width: 175px;
    }
  }

  &.Santoku {
    svg {
      height: 31px;
    }
  }
`

export const MaterialDescription = styled(Row)`
  width: 100%;
  background: white;
  padding: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  font-size: 13px;
  color: ${palette.darkBiege};
  position: relative;

  opacity: 0;
  animation: fadeIn ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 0.3s;
  transform: translateY(-1px);

  .knife-name {
    display: none;
  }

  b {
    font-family: 'Averta-Semibold', sans-serif;
  }

  @media (max-width: ${mediaQueries.small}) {
    & {
      padding: 40px;
    }

    .knife-name {
      text-align: left;
      color: ${palette.darkBlue};
      margin-bottom: 25px;
      display: block;
      width: 100%;
      margin-top: 20px;
    }
  }

  .close-button {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    border: none;
    background: none;
    outline: none;

    @media (max-width: ${mediaQueries.small}) {
      & {
        top: 30px;
        right: 10px;
      }
    }
  }

  .description-text {
    line-height: 23px;
  }

  .knife-params {
    color: ${palette.darkBlue};
    font-size: 11px;

    @media (max-width: ${mediaQueries.small}) {
      & {
        text-align: left;
        margin-top: 5px;
        padding-top: 25px;
        width: 100%;
        border-top: 1px solid ${palette.darkBlue};
      }
    }

    & > div {
      margin-bottom: 13px;

      &:last-child {
        margin-bottom: 35px;
      }
    }
  }

  .params {
    border-left: 1px solid ${palette.darkBlue};
    padding-left: 37px;
    padding-top: 20px;

    @media (max-width: ${mediaQueries.small}) {
      & {
        text-align: center;
      }
    }

    @media (max-width: ${mediaQueries.small}) {
      & {
        padding: 0;
        border-left: none;
        width: 100%;
      }
    }
  }
`

export const Title = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 70px;
  font-size: 10px;
  letter-spacing: 1px;
  color: #1d3041;
`

export const BranchTitle = styled(Title)`
  svg:first-child {
    transform: rotate(180deg);
  }

  display: flex;
  align-items: center;
  justify-content: center;

  span {
    display: inline-block;
    margin: 0 20px;
    height: 12px;

    @media (max-width: ${mediaQueries.small}) {
      & {
        margin: 0 5px;
      }
    }
  }

  &.blade-title {
    margin-top: 70px;
  }
`

export const VersatilityBar = styled.div`
  width: 105px;
  display: inline-block;
  margin-left: 5px;

  svg {
    margin: 0 6px;
  }
`

export const RenderVersatilityBar = (versatility: number): ReactElement => {
  return (
    <VersatilityBar>
      {_.times(
        5,
        (index): ReactElement =>
          versatility >= index + 1 ? (
            <SVGImage
              svgProps={[KlosySVGId.star, 'star-bar', 8, 8]}
              key={`star${index}`}
            />
          ) : (
            <SVGImage
              svgProps={[KlosySVGId.starEmpty, 'star-bar-empty', 8, 8]}
              key={`star${index}-empty`}
            />
          )
      )}
    </VersatilityBar>
  )
}

export const PresetGridContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
  display: grid;
  grid-template-columns:
    minmax(min-content, max-content) minmax(min-content, max-content)
    minmax(min-content, max-content);
  gap: 3px;

  @media (max-width: ${mediaQueries.small}) {
    display: none;
  }
`

export const PresetKnivesPreview = styled.div`
  height: 260px;
  overflow: hidden;
  padding: 0px 60px;

  & > div {
    text-align: right;
    height: auto;
    line-height: 0px;

    &.empty-preview {
      height: 29px;
    }
  }
`

export const PresetCol = styled.div`
  text-align: center;

  ${PresetKnivesPreview} {
    padding: 0px 60px;
    border-right: 1px solid ${palette.darkBlue};
    border-left: 1px solid ${palette.darkBlue};
  }

  &:first-child {
    ${PresetKnivesPreview} {
      padding-left: 30px;
      border-left: none;
      border-right: 1px solid ${palette.darkBlue};

      @media (max-width: ${mediaQueries.small}) {
        & {
          border-left: 1px solid ${palette.darkBlue};
          border-right: 1px solid ${palette.darkBlue};
        }
      }
    }
  }

  &:last-child {
    ${PresetKnivesPreview} {
      padding-right: 30px;
      border-right: none;
      border-left: 1px solid ${palette.darkBlue};

      @media (max-width: ${mediaQueries.small}) {
        & {
          border-left: 1px solid ${palette.darkBlue};
          border-right: 1px solid ${palette.darkBlue};
        }
      }
    }
  }
`

export const PresetTitle = styled.div`
  font-family: 'Averta-Bold', sans-serif;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
`

export const PresetKnivesNames = styled.div`
  line-height: 25px;
`

export const WhiteDivider = styled.div`
  width: 20px;
  height: 1px;
  background: white;
  margin: 0 auto;
`

export const PresetPrice = styled.div`
  span {
    font-family: 'Averta-Bold', sans-serif;
  }

  margin-bottom: 15px;
  margin-top: 10px;
`

export const DottedLine = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 6px !important;

  &::after {
    content: '..........................................................................................................';
    letter-spacing: 4px;
    display: inline-block;
    position: absolute;
    left: 0px;
    line-height: 0px;
  }
`
