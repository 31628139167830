import _ from 'lodash'

export const findImages = (data, imageNames: string[]): object[] => {
  return imageNames.map((name: string): object =>
    _.find(
      data.allFile.edges,
      (sharpNode: any): boolean => sharpNode.node.name === name
    )
  )
}

export const langIndex = (lang: string): number => (lang === 'PL' ? 0 : 1)
