import { StoreItem, StoreItemAccessory } from '~src/models/store'
import { mapComposer } from './mappers'
import { Events, ItemType, ItemDataType } from './types'

const preserveDataLayer = () => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({ ecommerce: null })
}

const getCookie = (name: string) => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2)
    return parts
      .pop()
      .split(';')
      .shift()

  return undefined
}
const PIXEL_TOKEN_ID =
  'EAAPPYm3XYkcBAKwyzNMvEx4rpTGmnZBIGDYHtE9XOvtOCLNCJbQVSRpZAbDTQMZBvQNM6hxWSM1DaYlibcHafzhnPbcpcXoMMgebYPkwi1ZAdSdMKh28IaqjD09lgkSyXArIyqYzvq2y5paZB0lf8l6424xheJNMLjou1bpbZBqmOA7IrBqOyiBV5MxsbsVBgZD'

const PIXEL_ID = '1543057512549033'

const pushDataLayer = (event: Events, data: unknown, extra?: object) => {
  window.dataLayer?.push({
    event,
    ecommerce: {
      items: data,
      ...extra,
    },
  })
}

export const viewStoreItemList = (
  knives: StoreItem[],
  accessory: StoreItemAccessory[]
) => {
  preserveDataLayer()

  const processedKnives = mapComposer('knife', knives, [
    'item_name',
    'item_id',
    'price',
    'item_variant',
    'index',
    'quantity',
  ])

  const processedAccessories = mapComposer('accessory', accessory, [
    'item_name',
    'item_id',
    'price',
    'item_variant',
    'index',
    'quantity',
  ])

  pushDataLayer('view_item_list', [...processedKnives, ...processedAccessories])
}

export const viewItemList = (type: ItemType, data: ItemDataType[]) => {
  preserveDataLayer()

  pushDataLayer(
    'view_item_list',
    mapComposer(type, data, [
      'item_name',
      'item_id',
      'price',
      'item_variant',
      'index',
      'quantity',
    ])
  )
}

export const viewItem = (
  type: ItemType,
  data: ItemDataType,
  index: number,
  currency: 'PLN' | 'EUR'
) => {
  preserveDataLayer()

  const viewItems = mapComposer(
    type,
    [data],
    ['item_name', 'item_id', 'price', 'quantity']
  )
  const selectItems = [
    {
      ...mapComposer(
        type,
        [data],
        ['item_name', 'item_id', 'price', 'quantity']
      )[0],
      index,
    },
  ]

  window.FB.api(
    `https://graph.facebook.com/v15.0/${PIXEL_ID}/events?access_token=${PIXEL_TOKEN_ID}`,
    'post',
    {
      data: [
        {
          event_name: 'ViewContent',
          event_time: Math.floor(Date.now() / 1000),
          user_data: {
            em: getCookie('_em'),
            fbc: getCookie('_fbc'),
            fbp: getCookie('_fbp'),
            client_user_agent: window.navigator.userAgent,
          },
          custom_data: {
            currency,
            value: viewItems[0]?.price,
            content_name: viewItems[0]?.item_name,
            content_ids: viewItems[0]?.item_id,
            // content_type: 'knife',
            // content_category: 'knife',
          },
          action_source: 'website',
          event_source_url: 'https://klosy.pl',
        },
      ],
    }
  )

  pushDataLayer('view_item', viewItems)
  pushDataLayer('select_item', selectItems)
}

export const beginCheckout = (
  type: ItemType,
  data: ItemDataType,
  currency: 'PLN' | 'EUR',
  url: string
) => {
  preserveDataLayer()

  const items = mapComposer(
    type,
    [data],
    ['item_name', 'item_id', 'price', 'quantity']
  )

  window.FB.api(
    `https://graph.facebook.com/v15.0/${PIXEL_ID}/events?access_token=${PIXEL_TOKEN_ID}`,
    'post',
    {
      data: [
        {
          event_name: 'AddToCard',
          event_time: Math.floor(Date.now() / 1000),
          user_data: {
            em: getCookie('_em'),
            fbc: getCookie('_fbc'),
            fbp: getCookie('_fbp'),
            client_user_agent: window.navigator.userAgent,
          },
          custom_data: {
            currency,
            value: items[0]?.price,
            content_name: items[0]?.item_name,
            content_ids: items[0]?.item_id,
            // content_type: 'knife',
            // content_category: 'knife',
          },
          action_source: 'website',
          event_source_url: url,
        },
      ],
    }
  )

  pushDataLayer('begin_checkout', items)
}

export const shippingInfo = (
  type: ItemType,
  data: ItemDataType[],
  shipping: string
) => {
  preserveDataLayer()

  const items = mapComposer(type, data, [
    'item_name',
    'item_id',
    'price',
    'quantity',
  ])

  pushDataLayer('add_shipping_info', items, {
    shipping_tier: shipping,
  })
}

export const purchase = (
  type: ItemType,
  data: ItemDataType[],
  currency: 'PLN' | 'EUR',
  price?: string,
  url?: string
) => {
  preserveDataLayer()

  const items = mapComposer(type, data, [
    'item_name',
    'item_id',
    'price',
    'quantity',
    'count',
    'item_variant',
  ])
  const extra = {
    currency,
    value: price
      ? price.replace(',', '.')
      : (+data[0]?.price[0]).toFixed(2) || '0.00',
    affiliation: url,
  }

  window.FB.api(
    `https://graph.facebook.com/v15.0/${PIXEL_ID}/events?access_token=${PIXEL_TOKEN_ID}`,
    'post',
    {
      data: items.map(i => ({
        event_name: 'Purchase',
        event_time: Math.floor(Date.now() / 1000),
        user_data: {
          em: getCookie('_em'),
          fbc: getCookie('_fbc'),
          fbp: getCookie('_fbp'),
        },
        custom_data: {
          currency,
          value: i?.price,
          content_name: i?.item_name,
          content_ids: i?.item_id,
          variant: i?.item_variant,
          affiliation: url,
        },
        action_source: 'website',
        event_source_url: url,
      })),
    }
  )

  pushDataLayer('purchase', items, extra)
}
