import _ from 'lodash'
import { StoreItem, StoreItemAccessory } from '~src/models/store'
import { Workshop } from '~src/models/workshop'
import { KnifeConfig } from '~src/types/knife'
import { ItemType, ItemDataType, ItemKeys } from './types'

const mapKnifeItem = (items: StoreItem[], keys: ItemKeys) => {
  return items.map((s, idx) => {
    const preprocessed = {
      item_name: s?.knife,
      item_id: s.id,
      price: (+s?.price[0])?.toFixed(2) || '0.00',
      item_variant: 'Knife',
      index: idx,
      quantity: '1',
    }

    return _.fromPairs(keys.map(key => [key, preprocessed[key]]))
  })
}

const mapAccessoryItem = (items: StoreItemAccessory[], keys: ItemKeys) => {
  return items.map((s, idx) => {
    const preprocessed = {
      item_name: s?.name[0],
      item_id: s.id,
      price: (+s?.price[0])?.toFixed(2) || '0.00',
      item_variant: 'Accessory',
      index: idx,
      quantity: '1',
    }

    return _.fromPairs(keys.map(key => [key, preprocessed[key]]))
  })
}

const mapWorkshopItems = (items: Workshop[], keys: ItemKeys) => {
  return items.map((s, idx) => {
    const preprocessed = {
      item_name: s?.title[0],
      item_id: s.id,
      price: (+s?.price[0])?.toFixed(2) || '0.00',
      item_variant: 'Workshop',
      index: idx,
      quantity: '1',
      count: `1`,
    }

    return _.fromPairs(keys.map(key => [key, preprocessed[key]]))
  })
}

const mapOrderKnife = (
  items: (KnifeConfig & {
    count?: number
  })[],
  keys: ItemKeys
) => {
  return items.map((s, idx) => {
    const preprocessed = {
      item_name: s?.name,
      item_id: s.id,
      price: (+s?.priceRow.PL)?.toFixed(2) || '0.00',
      item_variant: 'OrderKnife',
      index: idx,
      quantity: '1',
      count: `${s?.count || 1}`,
    }

    return _.fromPairs(keys.map(key => [key, preprocessed[key]]))
  })
}

export const mapComposer = (
  type: ItemType,
  data: ItemDataType[],
  keys: ItemKeys
) => {
  if (type === 'knife') return mapKnifeItem((data as StoreItem[]) || [], keys)
  if (type === 'orderKnife')
    return mapOrderKnife((data as KnifeConfig[]) || [], keys)
  if (type === 'accessory')
    return mapAccessoryItem((data as StoreItemAccessory[]) || [], keys)
  if (type === 'workshop')
    return mapWorkshopItems((data as Workshop[]) || [], keys)

  return []
}
